<template>
  <v-card :elevation="0">
    <v-toolbar tile flat>
      <v-toolbar-title>
        <h1 class="title font-weight-regular">
          {{ $tc('blog.SEO', 2) }} 
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="closePanelTool">mdi-close</v-icon>
    </v-toolbar>

    <v-tabs
      v-model="tabs"
      slider-size="2"
      fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text text-capitalize">
        {{ $t('blog.WEBSEO') }}
      </v-tab>
      <v-tab
        class="primary--text text-capitalize">
        {{ $t('blog.SHAREON') }}
      </v-tab>
      <v-tab
        class="primary--text text-capitalize">
        {{ $t('AVANCED') }}
      </v-tab>
      <v-tab-item class="mb-16">
        <v-card flat>
          <v-card-text>
            <h1 class="subtitle-1">{{ $t('blog.seo.GOOGLEPREVIEW') }}</h1>

            <v-card  class="mb-4" rounded="lg" outlined :elevation="0">
              <v-card-text class="pa-2">
                <h2 class="caption">/{{ editedItem.slug ? editedItem.slug : $tc('blog.seo.SLUGEXAMPLE') }}</h2>
                <h1 class="subtitle-1 font-weight-bold" :class="isDarkTheme ? 'indigo--text text--lighten-1' : 'indigo--text text--darken-1'">{{ editedItem.seo.title ? editedItem.seo.title : $tc('blog.seo.TITLEXAMPLE') }}</h1>
                <p class="caption">{{ editedItem.seo.description ? editedItem.seo.description : $tc('blog.seo.DESCRIPTIONEXAMPLE') }}</p>
              </v-card-text>
            </v-card>

            <v-text-field
              clearable
              v-model="editedItem.slug"
              :label="$t('blog.seo.SLUG')">
            </v-text-field>
            
            <v-text-field
              clearable
              counter="80"
              v-model="editedItem.seo.title"
              :label="$t('blog.seo.TITLE')">
            </v-text-field>

            <v-textarea
              class="mt-6"
              counter="320"
              outlined
              rows="3"
              v-model="editedItem.seo.description" >
              <template v-slot:label>
                <div>
                  {{ $t('blog.seo.DESCRIPTION') }}
                </div>
              </template>
            </v-textarea>

            <v-row class="mt-2">
              <v-col>
                <h1 class="mt-1 subtitle-2 font-weight-regular">
                {{ $t('blog.seo.SEARCHENGINES') }}<v-icon>mdi-information-outline</v-icon></h1> 
              </v-col>
              <v-col cols="2 px-0">
                <div>
                  <v-switch
                    dense
                    :ripple="false"
                    class="float-right mt-0"
                    v-model="editedItem.seo.searchEngine"
                    inset>
                  </v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item class="mb-16">
        <v-card flat>
          <v-card-text>
            <h1 class="subtitle-1 font-weight-bold">{{ $t('blog.social.SHARE') }}</h1>
            <p class="body-2">{{ $t('blog.social.SHAREDEF') }}</p>
            <h1 class="subtitle-1">{{ $t('blog.social.SOCIALPREVIEW') }}</h1>

            <v-card  class="mb-4 rounded-lg" tile outlined :elevation="0">
              <v-hover>
              <template v-slot:default="{ hover }">
                <v-card tile class="mb-0 rounded-t-lg" :elevation="0">
                  <v-img 
                    v-if="editedItem.image !== null"
                    max-height="160"
                    class="rounded-t-lg cursor-pointer"
                    :src="require(`@/assets/${editedItem.social.image}`)" 
                    :alt="editedItem.slug">
                  </v-img>
                  <v-card v-else elevation="0" min-height="160" :color="$vuetify.theme.dark ? 'drawernumber' : 'grey lighten-4'">
                    <v-card-text>
                      <v-card :class="$vuetify.theme.dark ? 'custom-card2' : 'custom-card'" height="128" outlined elevation="0">
                        <v-card-text class="text-center pt-8">
                          <v-icon size="60" :color="$vuetify.theme.dark ? 'drawerbackground' : 'drawernumber'">
                            mdi-image
                          </v-icon>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="primary">
                      <v-btn color="accent" fab small>
                        <v-icon v-if="editedItem.image !== null" color="primary">
                          mdi-cached
                        </v-icon>
                        <v-icon v-else color="primary">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
              <v-card-text class="pa-2">
                <h1 class="subtitle-1">{{ editedItem.social.title ? editedItem.social.title : $tc('blog.social.TITLEXAMPLE') }}</h1>
                <p class="caption">{{ editedItem.social.description ? editedItem.social.description : $tc('blog.social.DESCRIPTIONEXAMPLE') }}</p>
              </v-card-text>
            </v-card>
            
            <v-text-field
              clearable
              counter="80"
              v-model="editedItem.social.title"
              :label="$t('blog.social.TITLE')">
            </v-text-field>

            <v-textarea
              class="mt-6"
              counter="320"
              outlined
              rows="3"
              :label ="$t('blog.social.DESCRIPTION')"
              v-model="editedItem.social.description" >
              <template v-slot:label>
                <div>
                  {{ $t('blog.social.DESCRIPTION') }}
                </div>
              </template>
            </v-textarea>
          
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat class="mb-16">
          <v-card-text>
            <h1 class="subtitle-1">Datos estructurados
            <v-icon>mdi-information-outline</v-icon></h1> 
            <p class="body-2 mb-2">El campo del esquema de la entrada del blog se agregará automáticamente a esta página para ayudar a Google a mostrar un resultado enriquecido.</p>

            <a href="#">Mira si la página es elegible para resultados enriquecidos en Google</a>
            
            <h1 class="subtitle-1 mt-3">{{ $tc('blog.seo.DEFAULTMETA', 1) }}
            <v-icon>mdi-information-outline</v-icon></h1> 

            <meta-tags-item
              v-for="(tag, index) in editedItem.metaTags" 
              :key="index"
              :data="tag">
            </meta-tags-item>

            <h1 class="subtitle-1 mt-6">{{ $tc('blog.seo.CUSTOMMETA', 1) }}
            <v-icon>mdi-information-outline</v-icon></h1> 

            <meta-tags-item
              v-for="tag in editedItem.metaTagsCustom" 
              :key="tag.property"
              :custom="true"
              @remove = "removeMetaTag"
              :data="tag">
              <template slot="form">
                <div>
                  <v-row>
                    <v-col>
                      <h1 class="mt-1 subtitle-2 font-weight-regular">
                      {{ $tc('blog.seo.EDITTAG', 1) }}<v-icon>mdi-information-outline</v-icon></h1> 
                    </v-col>
                  </v-row>
                  <v-textarea
                    dense
                    hide-details=""
                    class="mt-2"
                    outlined
                    rows="5"
                    v-model="tag.value" >
                  </v-textarea>
                </div>
              </template>
            </meta-tags-item>

            <v-dialog
              v-model="dialog"
              persistent
              max-width="290">
              <template v-slot:activator="{ on: dialog }">
                <v-btn v-on="dialog" text outlined color="primary" class="text-upperfirst mt-4">
                  <v-icon left small>mdi-plus</v-icon> 
                  {{ $tc('blog.seo.CREATEMETATAG', 1) }}
                </v-btn>
              </template>

              <v-card>
                <v-card-text class="mt-2">
                  <v-row>
                    <v-col>
                      <h1 class="mt-1 subtitle-2 font-weight-regular">
                      {{ $tc('blog.seo.CREATEMETATAG', 1) }}<v-icon>mdi-information-outline</v-icon></h1> 
                    </v-col>
                  </v-row>
                  <v-textarea
                    dense
                    hide-details=""
                    class="mt-2"
                    outlined
                    rows="5"
                    v-model="editingTag.value" >
                  </v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    
                  <v-btn small @click="dialog = false" fab outlined>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                  <v-btn small fab @click="saveMetaTag()" color="primary">
                    <v-icon dark small>mdi-check</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>

export default {
  name: "post-seo-options",
  components: {
    MetaTagsItem: () => import(/* webpackChunkName: "metatagsitem" */ '@/components/MetaTagsItem.vue')
  },
  props: {
    editedItem: Object,
  },
  data() {
    return {
      tabs: null,
      switch1: true,
      switch2: true,
      dialog: false,
      editingTag: {
        value: "",
        include: true
      },
      defaultItem: {
        value: "",
        include: true
      }
    }
  },
  computed: {
    computedDateFormatted () {
      return this.fortmatDate(this.editedItem.published)
    },
    isDarkTheme(){
      return this.$vuetify.theme.dark;
    },
    exampleCode () {
      return `<meta property="${this.data.property}" content="${this.data.value}">`
    },
  },
  methods: {
    closePanelTool () {
      this.$emit('close')
    },
    isEmptyRelatives(value) {
      return value.relativeEntries.length > 0
    },
    fortmatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      if (this.$i18n.locale === 'en')
        return `${month}/${day}/${year}`
      else
        return `${day}/${month}/${year}`
    },
    removeMetaTag (data) {
      this.editedItem.metaTagsCustom.splice(data, 1)
    },
    saveMetaTag () {
      this.editedItem.metaTagsCustom.push(this.editingTag)
      this.editingTag = Object.assign({}, this.defaultItem)
      this.dialog = false
    }
  },
}
</script>

<style scoped>
  .v-card {
    width: 100%;
  }
  .custom-card {
    background:none;
    border: #cccccc dotted 2px;
  }
  .custom-card2 {
    background:#444444;
    border: #222222 dotted 2px;
  }
</style>
